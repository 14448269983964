<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习详情</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="t-contain">
        <div class="h-title">{{ infoData.practice_plan_name }}</div>
      </div>

      <div class="f-contain">
        <div class="f-title">基本信息</div>
        <div class="contain">
          <div class="item_c">实践课程： {{ infoData.course_name }}</div>
          <div class="item_c">实践类型： {{ infoData.config_type_name }}</div>
          <div class="item_c">
            实习时间：
            {{ infoData.start_date | dataformat }}
            <span
              v-if="infoData.start_date && infoData.end_date"
            >~</span>
            {{ infoData.end_date | dataformat }}
          </div>
          <div class="item_c">实习形式：{{ infoData.type | typeFilter }}</div>
          <div class="item_c">负责老师： {{ infoData.teacher_names }}</div>
        </div>
      </div>

      <div class="g-contain">
        <div class="f-title">实习介绍</div>
        <div class="contain">
          <div class="item_c">{{ infoData.remark }}</div>
        </div>
      </div>
      <div class="h-contain">
        <div class="f-title">实习任务</div>
        <div class="contain" v-for="(item,index) in settingList" :key="index">
          <div class="item_c" v-if="item.student_task.indexOf('1') != -1">
            <div class="text_t">学生签到</div>
            <div class="text_t2">
              签到规则：每天签到
              {{item.student_sign_in_count}}次
            </div>
          </div>
          <div class="item_c" v-if="item.teacher_task == '1'">
            <div class="text_t">教师签到</div>
            <div class="text_t2">
              签到规则：每天签到
              {{item.teacher_sign_in_count}}次
            </div>
          </div>
          <div class="item_c" v-if="item.student_task.indexOf('2') != -1">
            <div class="text_t">日报</div>
            <div class="text_t2">
              每{{ item.daily_report_list[0] }}天
              至少提交{{ item.daily_report_list[1]}}篇，
              提交总篇数不少于{{ item.daily_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c" v-if="item.student_task.indexOf('3') != -1">
            <div class="text_t">周报</div>
            <div class="text_t2">
              每{{ item.weekly_report_list[0] }}周
              至少提交{{ item.weekly_report_list[1]}}篇，
              提交总篇数不少于{{ item.weekly_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c" v-if="item.student_task.indexOf('4') != -1">
            <div class="text_t">月报</div>
            <div class="text_t2">
              每{{ item.monthly_report_list[0] }}月
              至少提交{{ item.monthly_report_list[1]}}篇，
              提交总篇数不少于{{ item.monthly_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c" v-if="item.student_task.indexOf('5') != -1">
            <div class="text_t">实习报告</div>
            <div class="text_t2">
              提交时间：
              {{ item.report_commit_start_date | dataformat}}
              <span
                v-if="item.report_commit_start_date && item.report_commit_end_date"
              >~</span>
              {{ item.report_commit_end_date | dataformat }}
            </div>
          </div>
        </div>
      </div>
      <div class="j-contain">
        <div class="f-title">实习报告</div>
        <div class="contain" v-for="(item,index) in settingList" :key="index">
          <div class="t_text">{{ item.url_name }}</div>
          <div
            class="image_con"
            @click="download(item.url, item.url_name)"
            v-if="item.url_name !=''"
          >
            <el-image class="elimage" :src="icon5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { getsetplaninfo } from "@/api/practiceresult"

const typeMap = ["全部", "自主报名", "集中安排"]

export default {
  name: "mainpage",
  components: {},
  data () {
    return {
      settingList: [], // 实习规则
      infoData: "",
      icon4: require("@/assets/查看.png"),
      icon5: require("@/assets/下载.png"),
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD")
      // return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeFilter (val) {
      return typeMap[val]
    },
  },
  computed: {
    practice_plan_id () {
      return this.$route.query.practice_plan_id
    },
    student_id () {
      return this.$route.query.student_id
    },
  },
  created () {
    this.getInfo() //获取实习计划设置详情
  },
  methods: {
    //获取实习计划详情
    getInfo () {
      let params = {
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      }
      getsetplaninfo(params).then((response) => {
        if (response.code === 0) {
          this.infoData = response.data
          this.settingList = response.data.settingInfo.map((item) => {
            let obj = {
              apply_type: item.apply_type,
              daily_report_list: item.daily_report ? item.daily_report.split(",") : [],
              weekly_report_list: item.weekly_report ? item.weekly_report.split(",") : [],
              monthly_report_list: item.monthly_report ? item.monthly_report.split(",") : [],
              report_commit_start_date: item.report_commit_start_date,
              report_commit_end_date: item.report_commit_end_date,
              student_task: item.student_task,
              student_sign_in_count: item.student_sign_in_count,
              teacher_task: item.teacher_task,
              teacher_sign_in_count: item.teacher_sign_in_count,
              url: item.url,
              url_name: item.url_name,
              evaluation_commit_end_date: item.evaluation_commit_end_date,
              evaluation_commit_start_date: item.evaluation_commit_start_date,
              practice_evaluation_template_url: item.practice_evaluation_template_url,
              practice_report_template_url: item.practice_report_template_url,
            }
            return obj
          })
        }
      })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 返回
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      })
    },
    // 下载
    download (url, name) {
      var ele = document.createElement("a") // 创建下载链接
      ele.download = name //设置下载的名称
      ele.style.display = "none" // 隐藏的可下载链接
      ele.href = url
      // 绑定点击时间
      document.body.appendChild(ele)
      ele.click()
      // 然后移除
      document.body.removeChild(ele)
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-top: 10px;
    background: #ffffff;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .line {
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    border-radius: 4px;
    padding-bottom: 20px;
    .t-contain {
      min-height: 70px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 10px;
      .h-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
    }
    .tip_con {
      margin-bottom: 10px;
      height: 32px;
      background-color: rgba(230, 247, 255, 1);

      border: 1px solid rgba(145, 213, 255, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .icon_con {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon_text {
          background: #0773fc;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          color: white;
          text-align: center;
        }
        .icon_name {
          margin-left: 6px;
          font-size: 16px;
          font-weight: 400;
          color: #666;
        }
      }
      .look_bt {
        font-size: 16px;
        font-weight: 400;
        color: #666;
        user-select: none;
        cursor: pointer;
      }
    }

    .f-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .item_c {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .g-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        .item_t {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
        }
        .item_c {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .h-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .item_c {
          margin-top: 10px;
          flex-basis: 50%;
          .text_t {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .text_t2 {
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }

    .j-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .t_text {
          font-size: 16px;
          font-weight: 400;
          color: #0773fc;
          cursor: pointer;
        }
        .image_con {
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
